import React, { Component } from 'react';
import { Table, ButtonGroup, Button, UncontrolledTooltip } from 'reactstrap';
import * as signalR from "@microsoft/signalr";
import authService from './api-authorization/AuthorizeService'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import TooltipHeader from './TooltipHeader';
import TooltipWrapper from './TooltipWrapper';
import TableRow from './TableRow';

export class Dashboard extends Component {
  static displayName = Dashboard.name;

  constructor(props) {
    super(props);
    this.state = {
      statistics: [],
      handlers: [],
      openHistories: [],
      lastUpdate: '',
      lastRefresh: '',
      user: '',
      sortConfig: { field: 'email', ascending: true },
      filters: { novaOnly: false, overZero: false, noLegalTeam: false },
      animationToggle: false,
      connectionState: '',
      loading: true
    };
  }

  async start() {
    try {
      await this.connection.start();
      console.log('Connected ' + new Date().toLocaleString('sv-SE'));
      this.setState({ connectionState: this.connection.state.toLowerCase() });
    } catch (err) {
      console.log(err);
      this.setState({ connectionState: this.connection.state.toLowerCase() });
      setTimeout(this, 5000);
    }
    };

  async componentDidMount() {
    this.fetchData();
    console.log('Mount');

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl('/statistics', { accessTokenFactory: async () => await authService.getAccessToken() })
      .withAutomaticReconnect()
      .build();

    // this.setState({ connection: this.connection });

    this.connection.on('Update', update => {
      console.log('Update ' + new Date().toLocaleString('sv-SE'));
      // console.log('Update ' + new Date().toLocaleString('sv-SE'), this, update);
      // let update2 = {...update};
      const { lastRefresh, loading, animationToggle } = this.state;
      this.setState({ statistics: update.statistics, lastUpdate: update.timestamp, animationToggle: !animationToggle, loading: false });
      if (new Date(lastRefresh).getDate() !== new Date().getDate() && !loading) {
        console.log('New day refresh', lastRefresh, new Date(lastRefresh));
        this.fetchData();
      }
      // const refresh = new Date(lastRefresh).getDate();
      // const upd = new Date(update2.timestamp).getDate();
      // console.log(refresh !== upd ? 'updated' : 'not updated', refresh, upd);
      // refresh !== upd && this.setState({ statistics: update2.statistics, lastRefresh: update2.lastRefresh });
      // update = null;
      // update2 = null;
    });

    this.connection.onreconnecting(error => {
      console.log('Reconnecting ' + new Date().toLocaleString('sv-SE'));
      this.setState({ connectionState: this.connection.state.toLowerCase() });
    });

    this.connection.onreconnected(() => {
      console.log('Reconnected ' + new Date().toLocaleString('sv-SE'));
      this.setState({ connectionState: this.connection.state.toLowerCase() });
    });

    // this.connection.start().then(() => {
    //   console.log("Connected");
    //   this.setState({ connectionState: this.connection.state.toLowerCase() });
    // }).catch((err) => {
    //   console.error(err.toString());
    //   this.setState({ connectionState: this.connection.state.toLowerCase() });
    // });

    this.connection.onclose(err => {
      console.log('Closed ' + new Date().toLocaleString('sv-SE'), err);
      this.start();
    });

    await this.start();

    var user = await authService.getUser();
    // console.log('User', user);

    this.setState({ user: user.name });
  }

  componentWillUnmount() {
    this.connection.stop();
    console.log('Disconnected ' + new Date().toLocaleString('sv-SE'));
  }

  setSortConfig = (field) => {
    let ascending = field === 'email' || field === 'handler';

    if (this.state.sortConfig.field === field) {
      ascending = !this.state.sortConfig.ascending;
    }

    this.setState({ sortConfig: { field, ascending }, openHistories: [] });
    console.log('Field set');
  }

  getHistory(user) {
    const { handlers } = this.state;
    return handlers.some(h => h.email === user)
      ? handlers.filter(h => h.email === user)[0]
      : null;
  }

  setHistoryOpen(user) {
    const { openHistories } = this.state;
    // console.log('Add user to open', user);

    if (openHistories.includes(user)) {
      this.setState({ openHistories: openHistories.filter(h => h !== user) });
    } else {
      this.setState({ openHistories: [user, ...openHistories] });
    }
  }

  renderStatisticsTable() {
    const { statistics, user, filters, sortConfig, openHistories } = this.state;
    let sortedStatistics = [...statistics];
    // console.log('User2', this.state);

    if (filters.novaOnly) {
      sortedStatistics = sortedStatistics.filter(s => s.handler);
    }
    if (filters.overZero) {
        sortedStatistics = sortedStatistics.filter(s => s.total > 0 || s.flagged > 0 || s.unread > 0 || s.incomingToday > 0);
    }
    if (filters.noLegalTeam) {
      sortedStatistics = sortedStatistics.filter(s => !s.isLegalTeam);
    }

    sortedStatistics.sort((a, b) => {
      if (!a[sortConfig.field]) {
        return 1;
      }
      if (!b[sortConfig.field]) {
        return -1;
      }
      if (a[sortConfig.field] < b[sortConfig.field]) {
        return sortConfig.ascending ? -1 : 1;
      }
      if (a[sortConfig.field] > b[sortConfig.field]) {
        return sortConfig.ascending ? 1 : -1;
      }
      return 0;
    });

    // console.log('Statistics', statistics);

    return (
      <Table striped hover size='sm' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <TooltipHeader className={`pointer sortable${sortConfig.field === 'email' ? sortConfig.ascending ? ' asc' : ' desc' : ''}`} id='email' onClick={() => this.setSortConfig('email')} tooltip='Användarnamn i Kundwebben'>Email</TooltipHeader>
            <TooltipHeader className={`pointer sortable${sortConfig.field === 'handler' ? sortConfig.ascending ? ' asc' : ' desc' : ''}`} id='handler' onClick={() => this.setSortConfig('handler')} tooltip='Användarnamn i Nova'>Handläggare</TooltipHeader>
            <TooltipHeader className={`pointer sortable${sortConfig.field === 'total' ? sortConfig.ascending ? ' asc' : ' desc' : ''}`} id='total' onClick={() => this.setSortConfig('total')} tooltip='Totalt antal inledande åtgärder i Nova'>Nova Prio</TooltipHeader>
            <TooltipHeader className={`pointer sortable${sortConfig.field === 'total2' ? sortConfig.ascending ? ' asc' : ' desc' : ''}`} id='total2' onClick={() => this.setSortConfig('total2')} tooltip='Totalt antal åtgärder i Nova'>Nova</TooltipHeader>
            <TooltipHeader className={`pointer sortable${sortConfig.field === 'incomingToday' ? sortConfig.ascending ? ' asc' : ' desc' : ''}`} id='incomingToday' onClick={() => this.setSortConfig('incomingToday')} tooltip='Antal inkomna meddelanden i Kundwebben idag'>Idag</TooltipHeader>
            <TooltipHeader className={`pointer sortable${sortConfig.field === 'flagged' ? sortConfig.ascending ? ' asc' : ' desc' : ''}`} id='flagged' onClick={() => this.setSortConfig('flagged')} tooltip='Antal flaggade meddelanden i Kundwebben'>Flaggade</TooltipHeader>
            <TooltipHeader className={`pointer sortable${sortConfig.field === 'unread' ? sortConfig.ascending ? ' asc' : ' desc' : ''}`} id='unread' onClick={() => this.setSortConfig('unread')} tooltip='Antal olästa meddelanden i Kundwebben'>Olästa</TooltipHeader>
          </tr>
        </thead>
        <tbody>
          {sortedStatistics.map(statistic =>
            <TableRow key={statistic.email} statistic={statistic} history={this.getHistory(statistic.email)} historyOpen={openHistories.includes(statistic.email)} setHistoryOpen={user => this.setHistoryOpen(user)} user={user} />
          )}
        </tbody>
      </Table>
    );
  }

  render() {
    // console.log('Render', this.state.sortConfig.field, this.state.sortConfig.ascending ? 'ascending' : 'descending');
    const { statistics, lastUpdate, lastRefresh, loading, filters, connectionState, animationToggle } = this.state;
    let contents = loading
      ? <p><em>Laddar...</em></p>
      : this.renderStatisticsTable();

    let timestamp = new Date(lastUpdate);
    let last = new Date(lastRefresh);

    return (
      <div>
        <ButtonGroup style={{ marginBottom: 3, paddingRight: 40 }}>
          <Button outline size='sm' onClick={() => this.setState({ filters: { ...filters, novaOnly: !filters.novaOnly } })} active={filters.novaOnly}>Endast Novahandläggare</Button>
          <Button outline size='sm' onClick={() => this.setState({ filters: { ...filters, overZero: !filters.overZero } })} active={filters.overZero}>Endast högre än 0</Button>
          <Button outline size='sm' onClick={() => this.setState({ filters: { ...filters, noLegalTeam: !filters.noLegalTeam } })} active={filters.noLegalTeam}>Inga jurister</Button>
        </ButtonGroup>
        <ButtonGroup style={{ marginBottom: 3 }}>
          <Button outline size='sm' onClick={() => this.setState({ openHistories: statistics.map(s => s.email) })}>Öppna alla</Button>
          <Button outline size='sm' onClick={() => this.setState({ openHistories: [] })}>Stäng alla</Button>
        </ButtonGroup>
        <span style={{ float: 'right', fontSize: '1.25em', paddingRight: '11px' }}>
          {connectionState === 'connected' ? <TooltipWrapper target='connected' tooltip='Liveuppdateringar ansluten'><i id='connected' className={'bi bi-cloud-check'} style={{ float: 'right', color: 'limegreen' }} /></TooltipWrapper> :
            connectionState === 'disconnected' ? <TooltipWrapper target='disconnected' tooltip='Anslutningen för liveuppdateringar misslyckades, testa att ladda om sidan'><i id='disconnected' className={'bi bi-cloud'} style={{ float: 'right', color: 'red' }} /></TooltipWrapper> :
            <TooltipWrapper target='reconnecting' tooltip='Liveruppdateringar försöker återansluta'><i id='reconnecting' className={'bi bi-arrow-repeat rotating'} style={{ float: 'right', paddingBottom: '1px', color: 'orange' }} /></TooltipWrapper>}
        </span>
        {!isNaN(timestamp.getTime()) && <TooltipWrapper target='timestamp' tooltip='Senast uppdaterad'><span id='timestamp' className={'flasher'} style={{ animation: `to-transparent-bg-${animationToggle ? 1 : 2} 1s linear forwards` }}>{timestamp.toLocaleString('sv-SE')}</span></TooltipWrapper>}
        {!isNaN(last.getTime()) && <span hidden>Last refresh: {last.toLocaleString('sv-SE')} (Server: {lastRefresh})</span>}
        {contents}
      </div>
    );
  }

  async fetchData() {
    const token = await authService.getAccessToken();
    const response = await fetch('api/statistics', {
      headers: token ? { 'Authorization': `Bearer ${token}` } : {}
    });

    if (response.status === 401) {
      console.log('401');
      this.props.history.push(ApplicationPaths.LogOut);
    } else {
      const data = await response.json();
      console.log('Fetched');
      this.setState({ statistics: data.statistics, handlers: data.handlers, lastUpdate: data.timestamp, lastRefresh: data.lastRefresh, loading: false });
    }
  }
}
