import React from 'react';
import { Table } from 'reactstrap';

const getMessageCount = messages => messages.map(dm => dm.count).reduce((a, b) => a + b, 0);

const getMessageCountFromDate = (messages, fromDate) =>
  getMessageCount(messages.filter(dm => new Date(dm.date) >= fromDate));

const getMessageCountBetweenDates = (messages, fromDate, toDate) =>
  getMessageCount(messages.filter(dm => new Date(dm.date) >= fromDate && new Date(dm.date) < toDate));

const getYesterday = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setDate(new Date().getDate() - 1);

  return date;
}

const getFirstDateOfCurrentWeek = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);

  while (date.getDay() !== 1) {
    date.setDate(date.getDate() - 1);
  }

  return date;
}

const getFirstDateOfLastWeek = () => {
  const thisWeek = getFirstDateOfCurrentWeek();
  thisWeek.setDate(thisWeek.getDate() - 7);

  return thisWeek;
}

const getFirstDateOfCurrentMonth = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setDate(1);

  return date;
}

const getFirstDateOfLastMonth = () => {
  const thisMonth = getFirstDateOfCurrentMonth();
  thisMonth.setMonth(thisMonth.getMonth() - 1);

  return thisMonth;
}

const getFirstDateOfCurrentYear = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setDate(1);
  date.setMonth(0);

  return date;
}

const getMonthName = date => {
  const monthName = new Intl.DateTimeFormat('sv-SE', { month: 'long' }).format(date);
  return monthName.charAt(0).toUpperCase() + monthName.slice(1);
}

const getFirstDateOfCurrentQuarter = () => {
  const date = new Date();

  if (date.getMonth() >= 9) {
    return { quarter: 4, firstDate: new Date(date.getFullYear(), 9, 1) };
  }
  if (date.getMonth() >= 6) {
    return { quarter: 3, firstDate: new Date(date.getFullYear(), 6, 1) };
  }
  if (date.getMonth() >= 3) {
    return { quarter: 2, firstDate: new Date(date.getFullYear(), 3, 1) };
  }

  return { quarter: 1, firstDate: new Date(date.getFullYear(), 0, 1) };
}

const getFirstDateOfLastQuarter = () => {
  const thisQuarter = getFirstDateOfCurrentQuarter();
  thisQuarter.firstDate.setMonth(thisQuarter.firstDate.getMonth() - 3);

  if (thisQuarter.quarter === 1) {
    thisQuarter.quarter = 4;
  } else {
    thisQuarter.quarter--;
  }

  return thisQuarter;
}

const TableRow = props => {
  const { statistic, history, user, historyOpen, setHistoryOpen } = props;
  // console.log('History', history);
  const { incoming, outgoing } = history || {};
  const { incomingToday, outgoingToday, color } = statistic;
  const yesterday = getYesterday();
  const week = new Date().setDate(new Date().getDate() - 7);
  const month = new Date().setDate(new Date().getDate() - 30);
  const quarter = new Date().setDate(new Date().getDate() - 90);
  const year = new Date().setDate(new Date().getDate() - 365);
  const thisWeek = getFirstDateOfCurrentWeek();
  const thisMonth = getFirstDateOfCurrentMonth();
  const thisQuarter = getFirstDateOfCurrentQuarter();
  const thisYear = getFirstDateOfCurrentYear();
  const lastWeek = getFirstDateOfLastWeek();
  const lastMonth = getFirstDateOfLastMonth();
  const lastQuarter = getFirstDateOfLastQuarter();
  const thisMonthName = getMonthName(thisMonth);
  const lastMonthName = getMonthName(lastMonth);
  // console.log('Date', quarter.toLocaleString('sv-SE'));
  // console.log(asd, color);
  // console.log('Nova', statistic.nova);
  // history &&
  //   (history.email === 'lisa.tuvert@ropocapital.se' || history.email === 'pernilla.almstrom@ropocapital.se' || history.email === 'victor.malm@ropocapital.se') &&
  //   console.log('History', history && history, history && history.incoming.filter(d => new Date(d) > yesterday));
  // console.log('FirstOfWeek', thisQuarter, lastQuarter);

  return (
    <>
      <tr style={historyOpen || statistic.email === user ? { backgroundColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.25)` } : {}} onClick={() => setHistoryOpen(statistic.email)}>
        <td>{statistic.email}</td>
        <td>{statistic.handler}</td>
        <td>{statistic.total}</td>
        <td>{statistic.total2}</td>
        <td>{incomingToday}</td>
        <td>{statistic.flagged}</td>
        <td>{statistic.unread}</td>
      </tr>
          {historyOpen &&
              <>
              <tr className='detail-view' onClick={() => setHistoryOpen(statistic.email)} >
            <td colSpan={7}>
              <>
                {history &&
                   <Table style={{ backgroundColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.05)`, marginBottom: '0.5rem' }} style={{ width: "100%" }}>
                    <thead>
                      <tr style={{ backgroundColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.05)` }}>
                        <th></th>
                        <th>Idag</th>
                        <th>Igår</th>
                        <th>Denna veckan</th>
                        <th>Förra veckan</th>
                        <th>7 dagar</th>
                        <th>{thisMonthName}</th>
                        <th>{lastMonthName}</th>
                        <th>30 dagar</th>
                        <th>{'Q' + thisQuarter.quarter}</th>
                        <th>{'Q' + lastQuarter.quarter}</th>
                        <th>90 dagar</th>
                        <th>{thisYear.getFullYear()}</th>
                        <th>1 år</th>
                        <th>Alla</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ backgroundColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.05)` }} >
                        <td style={{ fontWeight: 500 }}>Inkommande</td>
                        <td>{incomingToday}</td>
                        <td>{getMessageCountFromDate(incoming, yesterday)}</td>
                        <td>{getMessageCountFromDate(incoming, thisWeek) + incomingToday}</td>
                        <td>{getMessageCountBetweenDates(incoming, lastWeek, thisWeek)}</td>
                        <td>{getMessageCountFromDate(incoming, week) + incomingToday}</td>
                        <td>{getMessageCountFromDate(incoming, thisMonth) + incomingToday}</td>
                        <td>{getMessageCountBetweenDates(incoming, lastMonth, thisMonth)}</td>
                        <td>{getMessageCountFromDate(incoming, month) + incomingToday}</td>
                        <td>{getMessageCountFromDate(incoming, thisQuarter.firstDate) + incomingToday}</td>
                        <td>{getMessageCountBetweenDates(incoming, lastQuarter.firstDate, thisQuarter.firstDate)}</td>
                        <td>{getMessageCountFromDate(incoming, quarter) + incomingToday}</td>
                        <td>{getMessageCountFromDate(incoming, thisYear) + incomingToday}</td>
                        <td>{getMessageCountFromDate(incoming, year) + incomingToday}</td>
                        <td>{getMessageCount(incoming) + incomingToday}</td>
                      </tr>
                      <tr style={{ backgroundColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.05)` }}>
                        <td style={{ fontWeight: 500 }}>Utgående</td>
                        <td>{outgoingToday}</td>
                        <td>{getMessageCountFromDate(outgoing, yesterday)}</td>
                        <td>{getMessageCountFromDate(outgoing, thisWeek) + outgoingToday}</td>
                        <td>{getMessageCountBetweenDates(outgoing, lastWeek, thisWeek)}</td>
                        <td>{getMessageCountFromDate(outgoing, week) + outgoingToday}</td>
                        <td>{getMessageCountFromDate(outgoing, thisMonth) + outgoingToday}</td>
                        <td>{getMessageCountBetweenDates(outgoing, lastMonth, thisMonth)}</td>
                        <td>{getMessageCountFromDate(outgoing, month) + outgoingToday}</td>
                        <td>{getMessageCountFromDate(outgoing, thisQuarter.firstDate) + outgoingToday}</td>
                        <td>{getMessageCountBetweenDates(outgoing, lastQuarter.firstDate, thisQuarter.firstDate)}</td>
                        <td>{getMessageCountFromDate(outgoing, quarter) + outgoingToday}</td>
                        <td>{getMessageCountFromDate(outgoing, thisYear) + outgoingToday}</td>
                        <td>{getMessageCountFromDate(outgoing, year) + outgoingToday}</td>
                        <td>{getMessageCount(outgoing) + outgoingToday}</td>
                      </tr>
                    </tbody>
                  </Table>
                }
                {statistic.nova &&
                   <Table style={{ backgroundColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.05)`, marginBottom: '0.3rem' }} style={{ width: "100%" }}>
                    <thead>
                      <tr style={{ backgroundColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.05)` }}>
                        <th style={{ fontWeight: 500 }}>Bostad inledande</th>
                        <th style={{ fontWeight: 500 }}>Brf inledande</th>
                        <th style={{ fontWeight: 500 }}>Energi inledande</th>
                        <th style={{ fontWeight: 500 }}>Faktura inledande</th>
                        <th style={{ fontWeight: 500 }}>Hyra inledande</th>
                        <th style={{ fontWeight: 500 }}>Lokal inledande</th>
                        <th style={{ fontWeight: 500 }}>Totalt prio</th>
                        <th style={{ fontWeight: 500 }}>Totalt övrigt</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ backgroundColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.05)` }}>
                        <td>{statistic.nova.bostad_Inledande}</td>
                        <td>{statistic.nova.brf_Inledande}</td>
                        <td>{statistic.nova.energi_Inledande}</td>
                        <td>{statistic.nova.faktura_Inledande}</td>
                        <td>{statistic.nova.hyra_Inledande}</td>
                        <td>{statistic.nova.lokal_Inledande}</td>
                        <td>{statistic.total}</td>
                        <td>{statistic.total2}</td>
                      </tr>
                    </tbody>
                  </Table>
                }

                {!history && !statistic.nova &&
                  <div style={{ backgroundColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.05)`, fontWeight: 500 }}>Ingen historik</div>
                }
              </>
            </td>
          </tr>
          <tr></tr>
        </>
      }
    </>
  );
}

export default TableRow;