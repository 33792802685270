import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const TooltipHeader = props => {
  const { id, onClick, className, placement, tooltip, children } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <th className={className} id={id} onClick={onClick}>{children}</th>
      <Tooltip placement={placement || 'bottom'} isOpen={tooltipOpen} target={id} toggle={toggle} delay={{ show: 500, hide: 100 }}>
        {tooltip}
      </Tooltip>
    </>
  );
}

export default TooltipHeader;