import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const TooltipWrapper = props => {
  const { target, className, placement, tooltip, children } = props;

  return (
    <>
      {children}
      <UncontrolledTooltip placement={placement || 'bottom'} target={target} delay={{ show: 500, hide: 100 }}>
        {tooltip}
      </UncontrolledTooltip>
    </>
  );
}

export default TooltipWrapper;